import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ImpactsGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ImpactsGrid/ImpactsGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { IF } from "../../../../../components/hoc/If";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

export interface DetailedImpactsViewProps {}

const DetailedImpactsView: React.FunctionComponent<DetailedImpactsViewProps> = observer(() => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const { impactStore } = useStores();

  useEffect(() => {
    impactStore.canInvoke() && impactStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [impactStore, organisationId, projectId, authUser]);
  const isLoading = useMemo(() => {
    let res = impactStore.isLoading;
    if (impactStore.isCustomFieldEnabled) {
      res = res && (!impactStore.columnDefs || impactStore.columnDefs?.length > 0);
    }
    return res;
  }, [impactStore.isLoading, impactStore.isCustomFieldEnabled, impactStore.columnDefs]);

  return (
    <GridHubStoreWrapper store={impactStore} projectId={+projectId} organisationId={organisationId} authUser={authUser}>
      <div className="detailed-impacts-view insight-tab-content__space-filler">
        <IF condition={isLoading}>
          <PositionedSpinner></PositionedSpinner>
        </IF>
        <IF condition={!isLoading}>
          <ImpactsGrid
            isLoading={impactStore.isLoading}
            data={impactStore.data}
            backendColumnDefs={impactStore.columnDefs}
            connectedUsers={impactStore.connectedUsers}
            onCellStateChange={impactStore.updateUserSelectedCell}
          />
        </IF>
      </div>
    </GridHubStoreWrapper>
  );
});

export { DetailedImpactsView };
