import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React from "react";
import { Link, useHref } from "react-router-dom";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { useCanViewField } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { B } from "../../../../core/util/BootstrapHelper";
import { Enums, ProgressStatus } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { AvatarContent } from "../../../ui/AvatarContent";
import { Panel } from "../../../ui/Panel";
import { Pill, PillTypes } from "../../../ui/Pill";
import { Timeline } from "../../TimelineWidget";
import { IProjectInfoCardModel } from "./ProjectInfoCard_model";
import ActionTimeline from "./assets/Action_timeline.svg";
import AudienceMap from "./assets/Audience_map.svg";
import ImpactAssessment from "./assets/Impact_assessment.svg";
import NoAccess from "./assets/NoAccess.svg";
import StakeholderMap from "./assets/Stakeholder_map.svg";
import Pages from "../../../../routes/InsightRoutes";
import { getBasenameAsPrefix } from "../../../../core/util/Helpers";

export interface ProjectInfoCardProps {
  model?: IProjectInfoCardModel;
  projectData: FP.Entities.IProgrammeProject;
}

const ProjectInfoCardView: React.FunctionComponent<ProjectInfoCardProps> = observer(({ model, projectData }: any) => {
  const basename = useHref("/");
  let projectTeam = projectData.projectTeam === null ? [] : projectData.projectTeam.split("|");
  const cls = B().bl("project-info-card");
  let pillType = projectData.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;

  const organisationId = useCurrentOrganisationId();
  const canViewActions = useCanViewField(PermissionFields.ACTIONS, organisationId, projectData.id);
  const canViewImpacts = useCanViewField(PermissionFields.IMPACTS, organisationId, projectData.id);
  const canViewStakeholders = useCanViewField(PermissionFields.STAKEHOLDERS, organisationId, projectData.id);

  return (
    <Panel.Panel
      type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      className={`${cls.bem}`}
    >
      <div className="row">
        <div className="col-1 pt-2">
          <Link to={`${Pages.projects.index.generateLink(organisationId, projectData.id)}`}>
            <h1 className={`${cls.el("title").bem} text-truncate`} title={projectData.name}>
              {projectData.name}
            </h1>
            <Pill type={pillType} className="text-nowrap mt-3">
              {Enums.Translator.ProgressStatus(projectData.progressStatus)}
            </Pill>
          </Link>
        </div>
        <div className="col-2 pt-6">
          <Timeline
            startDate={new Date(projectData.startDate)}
            initialEndDate={new Date(projectData.endDate)}
            actualEndDate={new Date(projectData.endDate)}
          />
        </div>
        <div className="col-7">
          <div className="row">
            <div className="col-3">
              <Panel.Panel
                hasShadow={false}
                hasBorderRadius={true}
                background={PanelBackgrounds.BG_LIGHT}
                className="p-2 metric-card"
              >
                <div className="metric-card--data">
                  {canViewStakeholders && (
                    <a
                      href={`${getBasenameAsPrefix(basename)}${Pages.projects.stakeholders.listView.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <h2>Stakeholders</h2>
                      <div className="metric-card--data--count">{projectData.stakeholderCount}</div>
                    </a>
                  )}
                  {canViewStakeholders === false && (
                    <div>
                      <h2>Stakeholders</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewStakeholders && (
                    <a
                      href={`${getBasenameAsPrefix(
                        basename
                      )}${Pages.projects.stakeholders.visualisations.parameters.generateLink(
                        organisationId,
                        projectData.id,
                        "1",
                        "0"
                      )}`}
                    >
                      <img src={StakeholderMap} alt="Stakeholder Map" />
                    </a>
                  )}
                  {canViewStakeholders === false && <img src={NoAccess} alt="No Access" />}
                </div>
              </Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel
                hasShadow={false}
                hasBorderRadius={true}
                background={PanelBackgrounds.BG_LIGHT}
                className="p-2 metric-card"
              >
                <div className="metric-card--data">
                  {canViewStakeholders && (
                    <a
                      href={`${getBasenameAsPrefix(basename)}${Pages.projects.audiences.listView.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <h2>Audiences</h2>
                      <div className="metric-card--data--count">{projectData.audienceCount}</div>
                    </a>
                  )}
                  {canViewStakeholders === false && (
                    <div>
                      <h2>Audiences</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewStakeholders && (
                    <a
                      href={`${getBasenameAsPrefix(
                        basename
                      )}${Pages.projects.audiences.visualisations.index.generateLink(organisationId, projectData.id)}`}
                    >
                      <img src={AudienceMap} alt="Audience Map" />
                    </a>
                  )}
                  {canViewStakeholders === false && <img src={NoAccess} alt="No Access" />}
                </div>
              </Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel
                hasShadow={false}
                hasBorderRadius={true}
                background={PanelBackgrounds.BG_LIGHT}
                className="p-2 metric-card"
              >
                <div className="metric-card--data">
                  {canViewImpacts && (
                    <a
                      href={`${getBasenameAsPrefix(basename)}${Pages.projects.impacts.listView.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <h2>Impacts</h2>
                      <div className="metric-card--data--count">{projectData.impactCount}</div>
                    </a>
                  )}
                  {canViewImpacts === false && (
                    <div>
                      <h2>Impacts</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewImpacts && (
                    <a
                      href={`${getBasenameAsPrefix(
                        basename
                      )}${Pages.projects.impacts.visualisations.impactAssesment.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <img src={ImpactAssessment} alt="Impact Assessment" />
                    </a>
                  )}
                  {canViewImpacts === false && <img src={NoAccess} alt="No Access" />}
                </div>
              </Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel
                hasShadow={false}
                hasBorderRadius={true}
                background={PanelBackgrounds.BG_LIGHT}
                className="p-2 metric-card"
              >
                <div className="metric-card--data">
                  {canViewActions && (
                    <a
                      href={`${getBasenameAsPrefix(basename)}${Pages.projects.actions.listView.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <h2>Actions</h2>
                      <div className="metric-card--data--count">{projectData.actionCount}</div>
                    </a>
                  )}
                  {canViewActions === false && (
                    <div>
                      <h2>Actions</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewActions && (
                    <a
                      href={`${getBasenameAsPrefix(basename)}${Pages.projects.actions.visualisations.index.generateLink(
                        organisationId,
                        projectData.id
                      )}`}
                    >
                      <img src={ActionTimeline} alt="Audience Map" />
                    </a>
                  )}
                  {canViewActions === false && <img src={NoAccess} alt="No Access" />}
                </div>
              </Panel.Panel>
            </div>
          </div>
        </div>
        <div className="col-2 project-team">
          <h4 className="pb-2">Project Team</h4>
          {projectTeam.map(x => (
            <AvatarContent
              type="primary"
              avatarProps={{
                size: Enums.UiSizes.MD,
                imgSrc:
                  x.split("::")[1] ||
                  USER_IMAGE_PLACEHOLDER(x.split("::")[0].split(" ")[0], x.split("::")[0].split(" ")[1])
              }}
            ></AvatarContent>
          ))}
        </div>
      </div>
    </Panel.Panel>
  );
});

export { ProjectInfoCardView };
