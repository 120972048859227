import moment from "moment";
import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const DATE_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "startDate",
  headerName: "header Name",
  editable: true,
  ...columnOptions
});

export const DATE_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = moment(cellValue).format("DD/MM/YYYY") ?? "";

      let filterLocalDateAtMidnightToMoment = moment(filterLocalDateAtMidnight).format("DD/MM/YYYY");

      // Now that both parameters are Date objects, we can compare
      if (dateAsString < filterLocalDateAtMidnightToMoment) {
        return -1;
      } else if (dateAsString > filterLocalDateAtMidnightToMoment) {
        return 1;
      }
      return 0;
    },
    buttons: ["clear"]
  }
};
